import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore"
import MapView from "./MapView"
import Table from "./Grid"
import FilterBar from "./FilterBar"
import { telegram } from "../../utils/telegram.js"
const cityCoordinates = {
  "Abu Dhabi": { lat: 24.4539, lng: 54.3773 },
  Accra: { lat: 5.6037, lng: -0.187 },
  Amman: { lat: 31.9454, lng: 35.9284 },
  Amsterdam: { lat: 52.3676, lng: 4.9041 },
  Antalya: { lat: 36.8969, lng: 30.7133 },
  Athens: { lat: 37.9838, lng: 23.7275 },
  Auckland: { lat: -36.8485, lng: 174.7633 },
  "Bali (Denpasar)": { lat: -8.6705, lng: 115.2126 },
  Bangkok: { lat: 13.7563, lng: 100.5018 },
  Barcelona: { lat: 41.3851, lng: 2.1734 },
  Berlin: { lat: 52.52, lng: 13.405 },
  Bogotá: { lat: 4.711, lng: -74.0721 },
  Brisbane: { lat: -27.4698, lng: 153.0251 },
  Brussels: { lat: 50.8503, lng: 4.3517 },
  Budapest: { lat: 47.4979, lng: 19.0402 },
  "Buenos Aires": { lat: -34.6037, lng: -58.3816 },
  Cairo: { lat: 30.0444, lng: 31.2357 },
  Casablanca: { lat: 33.5731, lng: -7.5898 },
  "Cape Town": { lat: -33.9249, lng: 18.4241 },
  "Chiang Mai": { lat: 18.7883, lng: 98.9853 },
  Chicago: { lat: 41.8781, lng: -87.6298 },
  Christchurch: { lat: -43.532, lng: 172.6306 },
  "Ho Chi Minh City": { lat: 10.8231, lng: 106.6297 },
  Copenhagen: { lat: 55.6761, lng: 12.5683 },
  Darmstadt: { lat: 49.8716, lng: 8.6528 },
  Doha: { lat: 25.2854, lng: 51.531 },
  Dubai: { lat: 25.2048, lng: 55.2708 },
  Dublin: { lat: 53.3498, lng: -6.2603 },
  Dubrovnik: { lat: 42.6507, lng: 18.0944 },
  Edinburgh: { lat: 55.9533, lng: -3.1883 },
  Florence: { lat: 43.7696, lng: 11.2558 },
  Granada: { lat: 37.1773, lng: -3.5986 },
  Helsinki: { lat: 60.1699, lng: 24.9384 },
  "Hong Kong": { lat: 22.3193, lng: 114.1694 },
  Hvar: { lat: 43.1729, lng: 16.4412 },
  Istanbul: { lat: 41.0082, lng: 28.9784 },
  Jakarta: { lat: -6.2088, lng: 106.8456 },
  Jena: { lat: 50.9383, lng: 11.5761 },
  Jerusalem: { lat: 31.7683, lng: 35.2137 },
  Johannesburg: { lat: -26.2041, lng: 28.0473 },
  Kathmandu: { lat: 27.7172, lng: 85.324 },
  "Koh Phangan": { lat: 9.7319, lng: 100.0136 },
  "Kuala Lumpur": { lat: 3.139, lng: 101.6869 },
  Krakow: { lat: 50.0647, lng: 19.945 },
  Kyoto: { lat: 35.0116, lng: 135.768 },
  Lagos: { lat: 6.5244, lng: 3.3792 },
  Lima: { lat: -12.0464, lng: -77.0428 },
  Lisbon: { lat: 38.7223, lng: -9.1393 },
  London: { lat: 51.5074, lng: -0.1278 },
  "Los Angeles": { lat: 34.0522, lng: -118.2437 },
  Lyon: { lat: 45.764, lng: 4.8357 },
  Madrid: { lat: 40.4168, lng: -3.7038 },
  Manila: { lat: 14.5995, lng: 120.9842 },
  Marrakech: { lat: 31.6295, lng: -7.9811 },
  Melbourne: { lat: -37.8136, lng: 144.9631 },
  Medellín: { lat: 6.2476, lng: -75.5658 },
  "Mexico City": { lat: 19.4326, lng: -99.1332 },
  Miami: { lat: 25.7617, lng: -80.1918 },
  Milan: { lat: 45.4642, lng: 9.19 },
  Montreal: { lat: 45.5017, lng: -73.5673 },
  Moscow: { lat: 55.7558, lng: 37.6173 },
  "Mörfelden Walldorf": { lat: 49.9807, lng: 8.5615 },
  Munich: { lat: 48.1351, lng: 11.582 },
  Nairobi: { lat: -1.2921, lng: 36.8219 },
  "New Delhi": { lat: 28.6139, lng: 77.209 },
  "New York City": { lat: 40.7128, lng: -74.006 },
  Nice: { lat: 43.7102, lng: 7.262 },
  Osaka: { lat: 34.6937, lng: 135.5023 },
  Oslo: { lat: 59.9139, lng: 10.7522 },
  "Panama City": { lat: 8.9824, lng: -79.5199 },
  Paris: { lat: 48.8566, lng: 2.3522 },
  Perth: { lat: -31.9505, lng: 115.8605 },
  Phuket: { lat: 7.8804, lng: 98.3923 },
  Prague: { lat: 50.0755, lng: 14.4378 },
  "Quebec City": { lat: 46.8139, lng: -71.208 },
  Queenstown: { lat: -45.0312, lng: 168.6626 },
  Quito: { lat: -0.1807, lng: -78.4678 },
  Reykjavik: { lat: 64.1265, lng: -21.8174 },
  "Rio de Janeiro": { lat: -22.9068, lng: -43.1729 },
  Rome: { lat: 41.9028, lng: 12.4964 },
  Salzburg: { lat: 47.8095, lng: 13.055 },
  "San Francisco": { lat: 37.7749, lng: -122.4194 },
  "San Jose": { lat: 9.9281, lng: -84.0907 },
  Santiago: { lat: -33.4489, lng: -70.6693 },
  Seoul: { lat: 37.5665, lng: 126.978 },
  Seville: { lat: 37.3891, lng: -5.9845 },
  Singapore: { lat: 1.3521, lng: 103.8198 },
  "St. Petersburg": { lat: 59.9311, lng: 30.3609 },
  Split: { lat: 43.5081, lng: 16.4402 },
  Stockholm: { lat: 59.3293, lng: 18.0686 },
  Sydney: { lat: -33.8688, lng: 151.2093 },
  Taipei: { lat: 25.033, lng: 121.5654 },
  Tokyo: { lat: 35.6762, lng: 139.6503 },
  Toronto: { lat: 43.6532, lng: -79.3832 },
  Vancouver: { lat: 49.2827, lng: -123.1207 },
  Venice: { lat: 45.4408, lng: 12.3155 },
  Vienna: { lat: 48.2082, lng: 16.3738 },
  Volos: { lat: 39.3667, lng: 22.9439 },
  Warsaw: { lat: 52.2297, lng: 21.0122 },
  "Washington D.C.": { lat: 38.9072, lng: -77.0369 },
  Zurich: { lat: 47.3769, lng: 8.5417 },
}

const encodeCity = (city) => city.replace(/\s+/g, "-")
const decodeCity = (city) => city.replace(/-/g, " ")

const DEFAULT_CENTER = { lat: 13.7563, lng: 100.5018 }

const sortActivities = (activities) => {
  return activities.sort((a, b) => {
    const getSecondLetter = (address) => {
      const trimmed = (address || "").trim()
      return trimmed.length > 1 ? trimmed.charAt(1).toLowerCase() : ""
    }

    const secondLetterA = getSecondLetter(a.address)
    const secondLetterB = getSecondLetter(b.address)

    const isAlphaA = /^[a-z]$/i.test(secondLetterA)
    const isAlphaB = /^[a-z]$/i.test(secondLetterB)

    if (isAlphaA && isAlphaB) {
      if (secondLetterA === secondLetterB) {
        return (a.address || "").localeCompare(b.address || "")
      }
      return secondLetterA.localeCompare(secondLetterB)
    } else if (isAlphaA) {
      return -1
    } else if (isAlphaB) {
      return 1
    } else {
      return 0
    }
  })
}

const calculateRelevance = (activity) => {
  let relevance = 1

  if (activity.category?.toLowerCase() === "other") {
    relevance *= 0.3
  }

  if (activity.category?.toLowerCase() === "playground") {
    relevance += 0.2
  }

  if (!activity.photo_links || activity.photo_links.length === 0) {
    relevance *= 0.3
  }

  if (activity.user_ratings_total > 100) {
    const extraRelevance = Math.min(
      1,
      (activity.user_ratings_total / 10000) * 1
    )
    relevance += extraRelevance
  }

  return relevance
}

export default function Dashboard() {
  const { cityName } = useParams()
  const history = useHistory()
  const location = useLocation()

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search)
    return {
      category: searchParams.get("category") || "",
      age: searchParams.get("age") || "",
      page: parseInt(searchParams.get("page")) || 1,
    }
  }

  const [filters, setFilters] = useState({
    city: cityName || "Chiang Mai",
    ...getQueryParams(),
  })
  const [currentPage, setCurrentPage] = useState(getQueryParams().page)

  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)
  const [mapCenter, setMapCenter] = useState(
    cityCoordinates[cityName] || DEFAULT_CENTER
  )
  const [mapZoom, setMapZoom] = useState(cityName ? 12 : 5)
  const [hoveredActivity, setHoveredActivity] = useState(null)
  const activitiesPerPage = 16
  const [forceMapUpdate, setForceMapUpdate] = useState(0)

  const fetchActivities = useCallback(async (bounds) => {
    if (!bounds || typeof bounds !== "object") {
      telegram(`Invalid bounds object: ${JSON.stringify(bounds)}`)
      return
    }

    setLoading(true)
    const db = getFirestore()
    const activitiesRef = collection(db, "activities")

    let southwest, northeast

    if (bounds.getSouthWest && bounds.getNorthEast) {
      southwest = bounds.getSouthWest()
      northeast = bounds.getNorthEast()
    } else if (bounds.southwest && bounds.northeast) {
      southwest = bounds.southwest
      northeast = bounds.northeast
    } else {
      telegram(`Unrecognized bounds format: ${JSON.stringify(bounds)}`)
      setLoading(false)
      return
    }

    const BOUNDS_BUFFER = 0.02

    try {
      const q = query(
        activitiesRef,
        where("location.lat", ">=", southwest.lat - BOUNDS_BUFFER),
        where("location.lat", "<=", northeast.lat + BOUNDS_BUFFER)
      )

      const querySnapshot = await getDocs(q)

      const activitiesData = querySnapshot.docs
        .map((doc) => {
          const data = doc.data()
          return {
            id: doc.id,
            ...data,
            timestamp: data.timestamp ? new Date(data.timestamp) : new Date(),
          }
        })
        .filter((activity) => {
          return (
            activity.location.lng >= southwest.lng - BOUNDS_BUFFER &&
            activity.location.lng <= northeast.lng + BOUNDS_BUFFER &&
            activity.unique_name !== undefined &&
            activity.unique_name !== null // Filter for activities with unique_name
          )
        })

      console.log(
        `Fetched ${querySnapshot.docs.length} activities, filtered to ${activitiesData.length} with unique_name`
      )
      setActivities(activitiesData)
    } catch (error) {
      telegram(`Error fetching activities: ${error.toString()}`)
    } finally {
      setLoading(false)
    }
  }, [])

  const handleMapMove = useCallback(
    (mapInfo) => {
      console.log("Received mapInfo in handleMapMove:", mapInfo)

      if (!mapInfo || !mapInfo.bounds) {
        telegram(`Invalid mapInfo object: ${JSON.stringify(mapInfo)}`)
        return
      }

      const bounds = mapInfo.bounds
      fetchActivities(bounds)
    },
    [fetchActivities]
  )

  const updateUrlParams = useCallback(
    (newFilters, newPage) => {
      const searchParams = new URLSearchParams(location.search)
      let hasChanged = false

      if (newFilters.category !== searchParams.get("category")) {
        if (newFilters.category) {
          searchParams.set("category", newFilters.category)
        } else {
          searchParams.delete("category")
        }
        hasChanged = true
      }

      if (newFilters.age !== searchParams.get("age")) {
        if (newFilters.age) {
          searchParams.set("age", newFilters.age)
        } else {
          searchParams.delete("age")
        }
        hasChanged = true
      }

      if (newPage !== parseInt(searchParams.get("page"))) {
        if (newPage > 1) {
          searchParams.set("page", newPage.toString())
        } else {
          searchParams.delete("page")
        }
        hasChanged = true
      }

      const currentCity = decodeCity(location.pathname.split("/").pop())
      if (newFilters.city && newFilters.city !== currentCity) {
        const newPath = `/activity/city/${encodeCity(newFilters.city)}`
        if (hasChanged) {
          history.push(`${newPath}?${searchParams.toString()}`)
        } else {
          history.push(newPath)
        }
      } else if (hasChanged) {
        history.push(`${location.pathname}?${searchParams.toString()}`)
      }
    },
    [history, location]
  )

  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters(newFilters)
      setCurrentPage(1)
      updateUrlParams(newFilters, 1)

      if (newFilters.city && cityCoordinates[newFilters.city]) {
        const newCenter = cityCoordinates[newFilters.city]
        setMapCenter(newCenter)
        setMapZoom(12)

        const bounds = {
          southwest: {
            lat: newCenter.lat - 0.05,
            lng: newCenter.lng - 0.05,
          },
          northeast: {
            lat: newCenter.lat + 0.05,
            lng: newCenter.lng + 0.05,
          },
        }

        fetchActivities(bounds)

        setForceMapUpdate((prev) => prev + 1)
      } else {
        setMapCenter(DEFAULT_CENTER)
        setMapZoom(5)
        setForceMapUpdate((prev) => prev + 1)
      }
    },
    [updateUrlParams, fetchActivities]
  )

  useEffect(() => {
    if (cityName) {
      const decodedCityName = decodeCity(cityName)
      if (!cityCoordinates[decodedCityName]) {
        history.push("/")
        return
      }
      setFilters((prev) => {
        const newFilters = {
          ...prev,
          city: decodedCityName,
          ...getQueryParams(),
        }
        if (JSON.stringify(newFilters) !== JSON.stringify(prev)) {
          return newFilters
        }
        return prev
      })
    }

    const initialCity = cityName ? decodeCity(cityName) : "Chiang Mai"
    const center = cityCoordinates[initialCity] || DEFAULT_CENTER
    const initialBounds = {
      getSouthWest: () => ({
        lat: center.lat - 0.1,
        lng: center.lng - 0.1,
      }),
      getNorthEast: () => ({
        lat: center.lat + 0.1,
        lng: center.lng + 0.1,
      }),
    }
    fetchActivities(initialBounds)
    setMapCenter(center)
    setMapZoom(cityName ? 12 : 5)
  }, [cityName, fetchActivities, history, location.search])

  const filteredActivities = useMemo(() => {
    return sortActivities(
      activities.filter((activity) => {
        if (
          filters.category &&
          activity.category?.toLowerCase() !== filters.category.toLowerCase()
        )
          return false
        if (filters.age) {
          const selectedAge = parseInt(filters.age)
          if (
            selectedAge < activity.age_range_from ||
            selectedAge > activity.age_range_to
          )
            return false
        }
        return true
      })
    ).sort((a, b) => calculateRelevance(b) - calculateRelevance(a))
  }, [activities, filters])

  const totalPages = useMemo(() => {
    return Math.ceil((filteredActivities.length || 0) / activitiesPerPage)
  }, [filteredActivities, activitiesPerPage])

  const paginatedActivities = useMemo(() => {
    const startIndex = (currentPage - 1) * activitiesPerPage
    return filteredActivities.slice(startIndex, startIndex + activitiesPerPage)
  }, [filteredActivities, currentPage, activitiesPerPage])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    updateUrlParams(filters, newPage)
  }

  const handleActivityHover = (activity) => {
    setHoveredActivity(activity)
  }

  const handleActivityLeave = () => {
    setHoveredActivity(null)
  }

  return (
    <div className="flex flex-col">
      <FilterBar
        filters={filters}
        onFilterChange={handleFilterChange}
        cities={Object.keys(cityCoordinates)}
      />
      <div
        className="flex-grow flex flex-col md:flex-row-reverse relative"
        style={{ overflow: "visible" }}
      >
        <div className="w-full md:w-5/12 h-[calc(50vh-64px)] md:h-[calc(100vh-128px)]">
          <MapView
            activities={paginatedActivities}
            center={mapCenter}
            zoom={mapZoom}
            hoveredActivity={hoveredActivity}
            onMapMove={handleMapMove}
            forceUpdate={forceMapUpdate}
          />
        </div>
        <div className="w-full md:w-7/12 h-[calc(50vh-64px)] md:h-[calc(100vh-128px)] overflow-y-auto overflow-x-hidden md:overflow-x-hidden">
          <Table
            activities={paginatedActivities}
            onActivityHover={handleActivityHover}
            onActivityLeave={handleActivityLeave}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalActivities={filteredActivities.length}
            itemsPerPage={activitiesPerPage}
            city={filters.city || null}
          />
        </div>
      </div>
    </div>
  )
}
