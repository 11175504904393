import axios from "axios"

const chatId = "5954771071"

export const telegram = async (message) => {
  try {
    const apiUrl =
      "https://api.telegram.org/bot5744003668:AAHkp1H34QROaO4YJSMKQwjsylNp612tdoo/sendMessage"

    await axios.post(apiUrl, {
      chat_id: chatId,
      text: "Parents Dir, frontend, " + message,
    })
  } catch (error) {
    console.error("Error while sending error notification:", error)
  }
}
