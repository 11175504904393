import React from "react"
import "./assets/App.css"
import Header from "./pages/Landingpage/Header"
import Register from "./pages/Sign/Register"
import Login from "./pages/Sign/Login"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import ForgotPassword from "./pages/Sign/ForgotPassword"
import { AuthProvider } from "./context/AuthContext"
import Dashboard from "./pages/Landingpage/Dashboard"
import { HelmetProvider } from "react-helmet-async"
import ActivityDetail from "./pages/ActivityDetail" // Neue Komponente importieren

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen ">
            <Header />
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route
                path="/activity/city/:cityName"
                render={(props) => {
                  const decodedCityName = props.match.params.cityName.replace(
                    /-/g,
                    " "
                  )
                  return <Dashboard {...props} cityName={decodedCityName} />
                }}
              />
              <Route path="/activity/:uniqueName">
                <ActivityDetail />
              </Route>
              <Route exact path="/" component={Dashboard} />
            </Switch>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default App
